import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const app = initializeApp({
  apiKey: "AIzaSyCfHNhPhB9Jg6PvlOUgARzlEdMLRyeC1io",
  authDomain: "geethcuratins.firebaseapp.com",
  databaseURL: "https://geethcuratins-default-rtdb.firebaseio.com",
  projectId: "geethcuratins",
  storageBucket: "gs://geethcuratins.appspot.com/",
  messagingSenderId: "486031005299",
  appId: "1:486031005299:web:44a822416be15980a83c06",
  measurementId: "G-NCMXZCR4KZ",
});

export const analytics = initializeAnalytics(app);
export const Storage = getStorage(app);
export const db = getFirestore(app);
export default app;
