/* eslint-disable no-undef */
import React from "react";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";

function Footer() {
  const location = useLocation();
  const navigate = useNavigate();

  const linkStyle = {
    color: "white",
    textDecoration: "none",
    cursor: "pointer",
    paddingBottom: "2px",
    borderBottom: "1px solid transparent",
  };

  const activeLinkStyle = {
    ...linkStyle,
    borderBottom: "1px solid #FA9C26",
  };

  const getLinkStyle = (path) => {
    return location.pathname === path ? activeLinkStyle : linkStyle;
  };

  return (
    <div
      className="lg:p-4 py-4 lg:py-2 w-full "
      style={{ backgroundColor: "#121212" }}
    >
      <div className="content-wrapper grid w-full p-2 lg:p-3">
        <div className="col-12 lg:col-4 flex flex-column">
          <img
            src={require(`../logo.png`)}
            alt="Logo"
            style={{ width: "176px", marginLeft: "-27px" }}
            loading="lazy"
          />
          <i className="text-white mt-2">
            Innovative Options for the Modern World
          </i>
        </div>
        <div className="col-12 lg:col-4 flex flex-column md:flex-row justify-content-around flex-wrap lg:align-content-center sm:gap-2 my-4 lg:my-0 footer-navigations">
          <div style={getLinkStyle("/")} onClick={() => navigate("/")}>
            Home
          </div>
          <div
            style={getLinkStyle("/gallery")}
            onClick={() => navigate("/gallery")}
          >
            Gallery
          </div>
          <div style={getLinkStyle("/cart")} onClick={() => navigate("/cart")}>
            Cart
          </div>
          <div
            style={getLinkStyle("/contact-us")}
            onClick={() => navigate("/contact-us")}
          >
            Contact Us
          </div>
        </div>
        <div className="col-12 lg:col-4 flex justify-content-between lg:pl-6 align-items-center gap-3">
          <div className="text-white mt-2 lg:mt-0 flex flex-column">
            <a style={linkStyle} href="tel:+94718021976">
              +94 71 802 1976
            </a>
            <a href="mailto:geethcurtains@gmail.com" style={linkStyle}>
              geethcurtains@gmail.com
            </a>
          </div>
          <div>
            <Button
              icon="pi pi-facebook"
              className="btn-text-white p-button-text"
              onClick={() =>
                (window.location.href = "https://www.facebook.com")
              }
            />
            <Button
              icon="pi pi-youtube"
              className="btn-text-white p-button-text"
              onClick={() =>
                (window.location.href = "https://www.youtube.com/@geethcurtain")
              }
            />
          </div>
        </div>
      </div>
      <div
        className="col-12 mt-3 text-white"
        style={{
          borderTop: "1px solid #FFFFFF88",
        }}
      >
        <div className="grid content-wrapper lg:p-3 p-0 text-sm">
          <div className="col-12 md:col-6 text-left md:text-left">
            <span>Geeth Curtains v2.0.2 © 2024 - All rights reserved</span>
          </div>
          <div className="col-12 md:col-6 md:text-right flex lg:justify-content-end flex-column lg:flex-row">
            <a
              href="https://elixirlk.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              Elixir - Realizing Dreams through Enchanting Innovations
            </a>
            <span className="hide-on-mobile">&nbsp;|&nbsp;</span>
            <a
              href="http://www.dezign24x7.com"
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              Dezign24x7
            </a>
          </div>
        </div>
      </div>
      <a
        href="https://wa.me/94718021976"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp"
      >
        <i className="pi pi-whatsapp lg:text-4xl text-3xl"></i>
      </a>
    </div>
  );
}

export default Footer;
