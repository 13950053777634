let lastId = 0;

export default function reducer(state = { items: [] }, action) {
  switch (action.type) {
    case "itemAdded":
      return {
        items: [...state.items, { id: ++lastId, item: action.payload }],
      };
    case "itemRemoved":
      return { items: state.items.filter((rem) => rem.id !== action.payload) };
    case "itemsCleared":
      return { items: [] };
    default:
      return state;
  }
}
