import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { DataView } from "primereact/dataview";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

import galleryImage from "../assets/img/gallery.img/gallery.webp";

import { amount } from "CustomMath";

import "../assets/css/gallery.css";

const Gallery = () => {
  const [products, setProducts] = useState(null);
  const [sortKey, setSortKey] = useState("!order");
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortField, setSortField] = useState("order");

  const sortOptions = [
    { label: "Added: New to Old", value: "!order" },
    { label: "Added: Old to New", value: "order" },
    { label: "Price: High to Low", value: "!price" },
    { label: "Price: Low to High", value: "price" },
  ];

  useEffect(() => {
    const loadData = async () => {
      const data = [];
      const querySnapshot = await getDocs(collection(db, "Designs"));

      let order = 0;

      querySnapshot.forEach((doc) => {
        if (doc.exists && doc.data().Status) {
          const record = {
            id: doc.id,
            code: doc.id,
            name: doc.data().Name,
            description: doc.data().Description,
            image: doc.data().Image,
            price: doc.data().Price,
            order: order,
          };
          data.push(record);
          order++;
        }
      });
      setProducts(data);
    };

    loadData();
  }, []);

  const onSortChange = (event) => {
    const value = event.value;
    setSortOrder(value.startsWith("!") ? -1 : 1);
    setSortField(value.startsWith("!") ? value.substring(1) : value);
    setSortKey(value);
  };

  const renderGridItem = (data) => {
    return (
      <div className="col-6 lg:col-3 p-2">
        <Link to={`/item/${data.id}`} className="grid-item">
          <img
            alt="not found"
            src={`https://firebasestorage.googleapis.com/v0/b/geethcuratins.appspot.com/o/${data.image}?alt=media&token=debc5e8a-2c21-45a3-b0d6-50de7f8166d7`}
          />
          <div className="flex flex-column align-items-center gap-1">
            <label className="w-full ellipsis text-center cormorant-garamond text-secondary text-xl">
              {data.name}
            </label>
            <label className="w-full ellipsis text-center text-sm text-primary">
              <strong>{amount(data.price)} LKR</strong> - Per Meter
            </label>
            <label className="w-full ellipsis text-center text-sm text-primary">
              {data.code}
            </label>
          </div>
        </Link>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="grid mb-4">
        <div className="col-6">
          <Dropdown
            options={sortOptions}
            value={sortKey}
            optionLabel="label"
            placeholder="Sort By Price"
            onChange={onSortChange}
            style={{ maxWidth: "100%" }}
          />
        </div>
        <div className="col-6 text-right">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText className="w-full" placeholder="Keyword Search" />
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-column mb-6">
      <div
        className="bg-top bg-no-repeat flex flex-column align-items-center pt-7 page-image-header"
        style={{ backgroundImage: `url(${galleryImage})` }}
      >
        <h1 className="text-primary cormorant-garamond font-bold text-10xl">
          Gallery
        </h1>
        <h1 className="lg:text-8xl text-6xl lg:mt-5 mt-2 text-elegance">
          of Elegance
        </h1>
        <p className="text-primary lg:text-2xl text-lg text-center lg:mt-6 mt-4 gallery-p">
          Explore our wide range of latest curtain designs that will match all
          your needs at the most affordable price in Sri Lanka
        </p>
      </div>
      <div className="content-wrapper gallery mt-8">
        <DataView
          value={products}
          layout={"grid"}
          header={renderHeader()}
          itemTemplate={renderGridItem}
          paginator
          rows={48}
          sortOrder={sortOrder}
          sortField={sortField}
          gutter
        />
      </div>
    </div>
  );
};

export default Gallery;
