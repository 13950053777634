import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter as Router, Route, Routes } from "react-router-dom"; // Changed to HashRouter

import "primereact/resources/themes/mdc-light-deeppurple/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./assets/css/custom.css";
import "style.css";

import Home from "views/home";
import Gallery from "views/gallery";
import Cart from "views/cart";
import Item from "views/Item";

import ScrollToTop from "./components/ScrollToTop";

import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";

import Footer from "components/footer";
import Header from "components/header";
import ContactUs from "views/contact-us";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <ScrollToTop>
          <Header />
          <Routes>
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/item/:id" element={<Item />} />
            <Route path="/" element={<Home />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </Router>
    </PersistGate>
  </Provider>
);
