/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const cartSize = useSelector((state) => state.items.length);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const linkStyle = {
    color: "white",
    textDecoration: "none",
    cursor: "pointer",
    paddingBottom: "2px",
    borderBottom: "1px solid transparent",
  };

  const activeLinkStyle = {
    ...linkStyle,
    borderBottom: "1px solid rgb(250, 156, 38)",
  };

  const getLinkStyle = (path) => {
    return location.pathname === path ? activeLinkStyle : linkStyle;
  };

  const items = [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "Gallery",
      path: "/gallery",
    },
    {
      label: "Cart",
      path: "/cart",
      isCart: isMobile,
    },
    {
      label: "Contact Us",
      path: "/contact-us",
    },
  ].map((item) => ({
    ...item,
    template: (item) => (
      <div style={getLinkStyle(item.path)} onClick={() => navigate(item.path)}>
        {item.label}
        {item.isCart && ` (${cartSize})`}{" "}
      </div>
    ),
  }));

  const start = (
    <img
      style={{ height: "50px", cursor: "pointer" }}
      src={require(`../logo.png`)}
      alt=""
      onClick={() => navigate("/")}
    />
  );

  const end = (
    <div className="flex gap-4 align-content-center">
      {!isMobile ? (
        <span
          className="flex align-items-center"
          style={getLinkStyle("cart")}
          onClick={() => navigate("cart")}
        >
          View Cart
          <span
            style={{ color: "rgb(250, 156, 38)" }}
            className="pi pi-shopping-cart ml-3 mr-2"
          ></span>
          <span style={{ color: "rgb(250, 156, 38)" }}>{cartSize}</span>
        </span>
      ) : null}
      <span className="flex align-items-center">
        <span
          style={{ color: "#fff" }}
          className="pi pi-phone ml-3 mr-2"
        ></span>
        <a style={linkStyle} href="tel:+94718021976">
          +94 71 802 1976
        </a>
      </span>
      {isMobile ? (
        <Button
          icon="pi pi-bars"
          className="p-button-text btn-text-white"
          unstyled={true}
          onClick={() => setSidebarVisible(true)}
        />
      ) : null}
    </div>
  );

  return (
    <div className="header-container">
      <div
        className="w-100"
        style={{ height: "38px", backgroundColor: "#F1F1F1" }}
      >
        <div className="content-wrapper flex justify-content-end align-items-center gap-5 h-full">
          <div className="flex gap-2 align-items-end">
            <span style={{ lineHeight: "0.5rem" }} className="text-sm">
              A
            </span>
            <span style={{ lineHeight: "0.5rem" }}>A</span>
            <span style={{ lineHeight: "0.5rem" }} className="text-lg">
              A
            </span>
          </div>
          <div className="flex gap-2">
            <span>English</span>
            <span>සිංහල</span>
          </div>
        </div>
      </div>
      <div className="flex lg:px-5 px-2 header">
        <Menubar
          start={start}
          model={isMobile ? [] : items}
          end={end}
          className="content-wrapper menubar"
        />
      </div>
      <Sidebar
        visible={sidebarVisible}
        onHide={() => setSidebarVisible(false)}
        position="right"
        style={{ overflow: "hidden" }}
      >
        <ul className="p-menu-list" style={{ padding: 0, margin: 0 }}>
          {items.map((item, index) => (
            <li
              key={index}
              style={{
                listStyle: "none",
                margin: "16px 0",
                padding: "12px 20px",
                fontSize: "16px",
                color: "#000000",
                borderRadius: "4px",
                cursor: "pointer",
                transition: "background-color 0.3s, color 0.3s",
              }}
              onClick={() => {
                setSidebarVisible(false);
                navigate(item.path);
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#000000";
                e.target.style.color = "#FFFFFF";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "transparent";
                e.target.style.color = "#000000";
              }}
            >
              {item.label}
              {item.isCart && ` (${cartSize})`}{" "}
            </li>
          ))}
        </ul>
      </Sidebar>
    </div>
  );
};

export default Header;
