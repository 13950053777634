/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";

import Latest from "./home/latest";
import MeetUs from "./home/meet-is";

import "../assets/css/home.css";

import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

import left from "../assets/img/home.img/left.png";
import right from "../assets/img/home.img/right.webp";
import HowTo from "../assets/img/home.img/howto.png";
import Measure from "../assets/img/home.img/measure.webp";
import Quality from "../assets/img/home.img/quality.webp";
import Innovative from "../assets/img/home.img/innovative.webp";

const calculateNumVisible = (width) => {
  const isMobile = window.innerWidth <= 768;
  const itemWidth = isMobile ? 90 + 8 : 200 + 10;
  return Math.floor(width / itemWidth);
};

const Home = () => {
  const [products, setProducts] = useState([]);
  const [numVisible, setNumVisible] = useState(
    calculateNumVisible(window.innerWidth)
  );

  const navigate = useNavigate();

  useEffect(() => {
    loadData();
    const handleResize = () => {
      setNumVisible(calculateNumVisible(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const loadData = async () => {
    let data = [];
    const querySnapshot = await getDocs(collection(db, "Designs"));
    querySnapshot.forEach((doc) => {
      if (doc.exists && doc.data().Status) {
        const record = {
          id: doc.id,
          code: doc.id,
          name: doc.data().Name,
          description: doc.data().Description,
          image: doc.data().Image,
          price: doc.data().Price,
        };
        data.push(record);
      }
    });

    data = data.slice(Math.max(data.length - 15, 1));
    setProducts(data);
  };

  const productTemplate = (product) => {
    const isMobile = window.innerWidth <= 768;
    return (
      <Link to={`/item/${product.code}`}>
        <img
          className="cart-item-image"
          src={`https://firebasestorage.googleapis.com/v0/b/geethcuratins.appspot.com/o/${product.image}?alt=media&token=debc5e8a-2c21-45a3-b0d6-50de7f8166d7`}
          alt="not found"
          style={{ maxWidth: isMobile ? "100px" : "200px" }}
        />
      </Link>
    );
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div style={{ backgroundColor: "#fff" }} className="lg:pt-5 pt-7 pb-4">
        <div className="content-wrapper landing-wrapper">
          <img
            className="circle-image"
            src={require("../assets/img/home.img/circle.png")}
            alt="home image 1"
          />
          <h1
            style={{ fontSize: "9rem" }}
            className="text-primary font-bold mb-3 curtains-header"
          >
            Curtains
          </h1>
          <img
            className="home-image-1"
            src={require("../assets/img/home.img/home-image-1.jpg")}
            alt="home image 1"
          />
          <img
            className="home-image-3 hide-on-mobile"
            src={require("../assets/img/home.img/home-image-3.jpg")}
            alt="home image 3"
          />
          <img
            className="home-image-2"
            src={require("../assets/img/home.img/home-image-2.jpg")}
            alt="home image 2"
          />
          <div
            style={{
              width: "700px",
              marginTop: "-400px",
              marginLeft: "280px",
            }}
            className="flex flex-column align-items-center defines-header"
          >
            <h1 className="defines-text" style={{ fontSize: "9rem" }}>
              That
            </h1>
            <h1 className="defines-text" style={{ fontSize: "9rem" }}>
              Define Style
            </h1>
          </div>
          <Button
            label="Explore Our Range"
            icon="pi pi-angle-right"
            className="explore-button"
            onClick={() => navigate("/gallery")}
            text
          />
        </div>
        <div className="content-wrapper lg:pt-8">
          <div className="grid lg:mt-7 l02g:px-8 frame-designs">
            <div className="col-3 flex align-items-center justify-content-center home-frame-wraper">
              <div>
                <img
                  className="home-frames"
                  src={require("../assets/img/home.img/Layer-1.jpg")}
                  alt="home frame 1"
                />
              </div>
              <div className="center-items frame-paras">
                <p>
                  Home and
                  <br />
                  Office Solutions
                </p>
              </div>
            </div>
            <div className="col-3 flex align-items-center justify-content-center home-frame-wraper ">
              <div>
                <img
                  className="home-frames"
                  src={require("../assets/img/home.img/Layer-2.jpg")}
                  alt="home frame 2"
                />
              </div>
              <div className="center-items frame-paras">
                <p>
                  Islandwide
                  <br />
                  Service
                </p>
              </div>
            </div>
            <div className="col-3 flex align-items-center justify-content-center home-frame-wraper">
              <div>
                <img
                  className="home-frames"
                  src={require("../assets/img/home.img/Layer-3.jpg")}
                  alt="home frame 3"
                />
              </div>
              <div className="center-items frame-paras">
                <p>
                  Consultation
                  <br />
                  and Advice
                </p>
              </div>
            </div>
            <div className="col-3 flex align-items-center justify-content-center home-frame-wraper">
              <div>
                <img
                  className="home-frames"
                  src={require("../assets/img/home.img/Layer-4.jpg")}
                  alt="home frame 4"
                />
              </div>
              <div className="center-items frame-paras">
                <p>
                  Satisfaction
                  <br />
                  Guarantee
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:pt-6 pb-6">
        <div className="content-wrapper">
          <div className="grid">
            <div className="col-12 lg:col-7">
              <h5 className="text-secondary cormorant-garamond text-5xl lg:text-7xl">
                <span className="font-semibold">Innovative</span> options
              </h5>
              <h5
                className="text-secondary cormorant-garamond text-5xl lg:text-7xl"
                style={{ lineHeight: "3rem" }}
              >
                for the modern world
              </h5>
              <div className="lg:mt-6 flex">
                <div className="w-100" style={{ maxWidth: "600px" }}>
                  <h3 className="text-5xl">Geeth Curtains</h3>
                  <p className="text-color">
                    Over a decade ago, we embarked on our entrepreneurial
                    journey with the launch of Geeth Curtains near Walpola
                    railway station. Throughout these twelve years, we&apos;ve
                    dedicated ourselves to providing honest, cooperative, and
                    amicable service to our valued customers. We&apos;ve strived
                    to understand your heartfelt inspirations, wishes, economic
                    constraints, and requirements.
                  </p>
                  <p className="text-color">
                    In response to the trust and support bestowed upon us by our
                    thousands of sincere customers, we&apos;ve expanded our
                    presence. Today, we proudly announce the opening of our new
                    showroom opposite Ragama Railway Station. This expansion
                    marks our commitment to enhancing our service and meeting
                    your needs more effectively. With three additional branches
                    and a team of skilled staff, we are now known as Geeth
                    Curtain Designs, an esteemed and renowned entrepreneur.
                  </p>
                  <p className="text-color">
                    Our journey&apos;s success isn&apos;t measured by mere
                    achievements but by the enduring affection, cooperation, and
                    trust you&apos;ve bestowed upon us. As we look to the
                    future, we are eager to continue providing unparalleled,
                    friendly service. Your unwavering support inspires us to
                    strive for excellence in everything we do.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:col-5 hide-on-mobile">
              <img
                alt="measure"
                src={Innovative}
                loading="lazy"
                className="innovative-image"
              />
            </div>
          </div>
        </div>
      </div>
      <Latest />
      <div className="info-wrapper mt-6 lg:mt-8 lg:mb-6 lg:pb-6">
        <div className="content-wrapper">
          <h2 className="flex justify-content-center text-secondary cormorant-garamond crafted">
            Crafted for <i className="font-semibold luxury">Luxury</i>
          </h2>
          <div className="grid mt-6">
            <div className="lg:col-4">
              <img
                alt="measure"
                src={left}
                loading="lazy"
                className="w-full info-left -mt-5"
              />
            </div>
            <div className="lg:col-4 text-info lg:pb-2 pb-6 px-2">
              <p>
                We prioritize efficiency and affordability by leveraging modern
                technology in our production processes, ensuring minimized costs
                for you. With a diverse range of curtain designs available, we
                cater to various preferences and budgets. Additionally, our
                rapid service guarantees prompt delivery, even for urgent
                orders, right to your doorstep.
              </p>
              <p>
                At Geeth Curtain Designs, we maintain extensive material stocks,
                unaffected by prevailing inflation rates. Coupled with years of
                craftsmanship experience, we promise efficient and reliable
                service. Our expertise in modern technology enables us to offer
                tailored designs that perfectly suit your needs and preferences.
              </p>
              <p>
                Customer satisfaction is our utmost priority. If you&apos;re
                ever dissatisfied with our service, we offer a no-deduction
                refund policy. Moreover, our in-house manufacturing of curtain
                poles ensures both aesthetic appeal and durability, enhancing
                the overall look of your curtains. With flexible payment options
                and convenient consultation timings, we strive to make your
                experience with us seamless and satisfying.
              </p>
            </div>
            <div className="lg:col-4 desktop-specs">
              <img
                alt="measure"
                src={right}
                loading="lazy"
                className="mt-8"
                style={{ width: "20vw", borderRadius: "8px", float: "right" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-6 pt-6">
        <div className="content-wrapper">
          <h2 className="flex justify-content-end text-secondary cormorant-garamond opacity-60">
            Vlogs <span className="recent-vlogs-text">Recent</span>
          </h2>
          <div className="grid lg:mt-0 mt-6">
            <div className="lg:col-4 col-12 flex flex-column gap-2 vlog-measure lg:p-3">
              <img
                style={{ marginTop: "10px" }}
                alt="measure"
                src={Measure}
                loading="lazy"
                className="vlog-iamge mb-3"
              />
              <div className="button-vlog-1">
                <h4>How to Measure</h4>
                <Button
                  label="Watch Now"
                  icon="pi pi-angle-right"
                  iconPos="right"
                  className="p-button-outlined p-button-sm btn-outlined w-max"
                  onClick={() =>
                    window.open(
                      "https://www.youtube-nocookie.com/embed/wUpFYBftxr4",
                      "_blank"
                    )
                  }
                />
              </div>
            </div>
            <div className="lg:col-4 col-12 flex lg:flex-column flex-column-reverse gap-2 vlog-website lg:p-3">
              <div className="button-vlog-2">
                <h4>How to Use the Website</h4>
                <Button
                  label="Watch Now"
                  icon="pi pi-angle-right"
                  iconPos="right"
                  className="p-button-outlined p-button-sm btn-outlined w-max"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/embed/paxbnsBuqcg",
                      "_blank"
                    )
                  }
                />
              </div>
              <img
                alt="howto"
                src={HowTo}
                loading="lazy"
                className="vlog-iamge mt-3"
              />
            </div>
            <div className=" lg:col-4 col-12 flex flex-column gap-2 vlog-quality lg:p-3">
              <img
                alt="quality"
                src={Quality}
                loading="lazy"
                className="vlog-iamge mb-3"
              />
              <div className="button-vlog-3">
                <h4>Quality of Curtains</h4>
                <Button
                  label="Watch Now"
                  icon="pi pi-angle-right"
                  iconPos="right"
                  className="p-button-outlined p-button-sm btn-outlined w-max"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/embed/8d62oBDLCTI",
                      "_blank"
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#eaecec" }}>
        <MeetUs />
      </div>
      <div
        className="lg:pt-5 flex flex-column align-items-center gap-1 hide-on-mobile"
        style={{ backgroundColor: "#eaecec" }}
      >
        <div className="page-context">GEETH CURTAINS</div>
        <div className="page-context">
          Innovative Options for the Modern World
        </div>
      </div>
      <div
        className="pb-2 hide-on-mobile"
        style={{ backgroundColor: "#eaecec" }}
      >
        <Carousel
          value={products}
          numVisible={numVisible}
          circular
          autoplayInterval={3000}
          itemTemplate={productTemplate}
          className="pt-5 cursor-pointer"
          showIndicators={false}
        />
      </div>
    </div>
  );
};

export default Home;
