import axios from "axios";

const local = "https://localhost:7112/api/orders";
const cloud = "https://prageethperera-001-site1.etempurl.com/api/orders";

export default class MailService {
  postToEndPoint(data) {
    return new Promise((resolve) => {
      axios
        .post(cloud, data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error);
        });
    });
  }
}
