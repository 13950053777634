export function ceil(value, exp) {
  return decimalAdjust("ceil", value, exp);
}

export function floor(value, exp) {
  return decimalAdjust("floor", value, exp);
}

export function numberWithCommas(x) {
  return x?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function amount(x) {
  return x?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function decimalAdjust(type, value, exp) {
  if (typeof exp === "undefined" || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN;
  }
  
  value = value.toString().split("e");
  value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));

  value = value.toString().split("e");
  return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
}
